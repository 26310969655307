div.navcontrol {
  position: fixed;
  bottom: 5px;
  right: 5px;
  background-color: #efefef;
  padding: 5px;
  border-radius: 4px;
}

div.pictures {
  p.caption strong {
    color: #393939;
  }
}

.navbar-item {
  padding: 0.5rem 0.25rem;
}
