// Green #00c300
// $body-background-color

// $background: $white-ter:

// $link: $blue;

// $primary: $turquoise;
// $link: $blue;
// $info: $cyan;
// $success: $green;
// $warning: $yellow;
// $danger: $red;
// $dark: $grey-darker;
// // $text: $grey-dark
// $text: $white-ter;

@import "node_modules/bulma/sass/utilities/initial-variables";
// $background: #272727;
$body-background-color: $grey-darker;
// $text: #ffffff;
$text: $grey-dark;

$button-color: black;
$primary: #00c300;

$text-strong: $white-ter;

@import "bulma";

// iPhone 6/7/8 portrait
$break-small: 375px;

body {
  padding-top: 140px;
}

div.pictures div.box {
  @media screen and (max-width: $break-small) {
    // border: 1px solid red;
    // Slighly less at the bottom
    padding: 6px 6px 3px 6px;
  }
}

div.pictures div.box p.caption {
  @media screen and (max-width: $break-small) {
    // border: 1px solid red;
    // Slighly less at the bottom
    padding: 10px;
  }
}

div.loading-picture {
  text-align: center;
  vertical-align: middle;
  width: 600px;
  height: 450px;
  border: 1px solid #ccc;
}

section.section {
  @media screen and (max-width: $break-small) {
    // border: 2px solid red;
    padding: 2rem 0.5rem;
  }

  article.removed-card {
    opacity: 0.7;
    h3 {
      font-size: 0.9em;
    }
  }
}

.box a:visited {
  color: $purple;
}

.version-info {
  text-align: center;
  margin: 20px;
  font-size: 80%;
}

.grid .box {
  a {
    display: inline-block;
    position: relative;
    width: 74px;
    height: 74px;
    overflow: hidden;
    margin-right: 2px;
    margin-bottom: 2px;

    img {
      position: absolute;
      max-width: 100%;
      width: 100%;
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      width: auto;
    }
  }
}

.input {
  color: #333;
}
